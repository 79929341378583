import React, { useState, useEffect } from 'react';
import firebase from 'firebase/app';
import 'firebase/firestore';

const useGetData = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const db = firebase.firestore();
      const dataCollection = await db.collection('deckRatings').orderBy('lastUpdated', 'desc').get(); // Vervang 'your_collection_name' met je eigen collectienaam
      const dataArray = dataCollection.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setData(dataArray);
    };

    fetchData();
  }, []);

  return data;
}

export default useGetData;


