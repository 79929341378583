/**
 * Displays the dashboard page.
 */

import React, { useState, useContext } from 'react';
import { Link, Switch, Route } from 'react-router-dom';
import { firebaseAuth } from '../provider/AuthProvider';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRandom, faPlus } from '@fortawesome/free-solid-svg-icons';
import Data from './Data';
import Mindmap from './Mindmap';
import Breadcrumb from './Breadcrumb';
import DeckCreator from './decks-and-cards/DeckCreator';
import DeckEditor from './decks-and-cards/DeckEditor';
import DeckList from './decks-and-cards/DeckList';
import PageHeading from './PageHeading';
import Analyse from './decks-and-cards/Analyse';
import AnalyseData from './decks-and-cards/AnalyseData';
const Dashboard = ({
  onClick,
  decks,
  cards,
  levels,
  data,
  selectedDecks,
  setSelectedDecks,
}) => {
  const [deckToEdit, setDeckToEdit] = useState(null);
  const { user } = useContext(firebaseAuth);

  // if (!user) {
  //   return (
  //     <div className="dashboard">
  //       <p>You are not logged in. To view your dashboard, log in or sign up here:</p>
  //       <Link to="/">Home</Link>
  //     </div>
  //   );
  // }

  return (
    <div className="dashboard">
      <div className="dashboard-inner">
        <Switch>
          <Route path="/app">
            <Breadcrumb
              to="/"
              name="Home"
            /> 
            <PageHeading 
              title="Ontdek je fase."
              subtitle="Beantwoord de vragen en bekijk de fase"
            />
            <Analyse
              levels={levels}
               />
          </Route>
          <Route path="/appData">
            <Breadcrumb
              to="/"
              name="Home"
            />
            <PageHeading 
              title="Analyseer je fase."
              subtitle="Beantwoord de vragen en bekijk de fase"
            />
            <AnalyseData
              levels={levels}
               />
          </Route>
          <Route path="/data">
            <Breadcrumb
              to="/"
              name="Home"
            />
            <PageHeading 
              title="Analyseer de data."
              subtitle="Beantwoord de vragen en bekijk de fase"
            />
            <Data
              data={data}
               />
          </Route>
          <Route path="/mindmap">
            <Breadcrumb
              to="/"
              name="Home"
            />
            <PageHeading 
              title="Analyseer de data."
              subtitle="Beantwoord de vragen en bekijk de fase"
            />
            <Mindmap
              data={data}
               />
          </Route>
        </Switch>
      </div>
    </div>
  );
} 

export default Dashboard;