import { useState, useEffect } from 'react';
import firebase from 'firebase/app';
import 'firebase/firestore';

const useGetLevels = () => {
  const db = firebase.firestore();
  const [levels, setLevels] = useState({});

  useEffect(() => {
    db.collection('levels')
      .get()
      .then(snapshot => {
        const levelsData = {};
        snapshot.forEach(doc => {
          const levelData = doc.data();
          // Zorg ervoor dat zowel titles als link bestaan en correct zijn
          if (levelData.titles && Array.isArray(levelData.titles) && levelData.link) {
            levelsData[doc.id] = {
              titles: levelData.titles,
              link: levelData.link, // Sla de link op onder hetzelfde level object
              name: levelData.name,
              exitLink : levelData.exitLink
            };
          }
        });
        setLevels(levelsData);
        // Uncomment onderstaande regel voor debug doeleinden
        // console.log("Levels data updated: ", levelsData);
      })
      .catch(error => {
        console.log("Error fetching levels: ", error.message);
      });
  }, []);

  return levels;
}

export default useGetLevels;
