/**
 * Initialize firebase authentication and firestore.
 */

import firebase from 'firebase';
import 'firebase/auth';
import 'firebase/app';
import 'firebase/firestore';

var firebaseConfig = {
  apiKey: "AIzaSyA3eK6vq4Q3Zg4_fG5IheOqrECQVCIiiS0",
  authDomain: "analysetool-jgm.firebaseapp.com",
  databaseURL: "https://analysetool-jgm-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "analysetool-jgm",
  storageBucket: "analysetool-jgm.appspot.com",
  messagingSenderId: "188690268856",
  appId: "1:188690268856:web:4a2cec9212ccb23014aa4d",
  measurementId: "G-6G48T6FJGQ"
};

firebase.initializeApp(firebaseConfig);
export const auth=firebase.auth();
export const db=firebase.firestore();
export const fb=firebase;

export default { firebaseConfig }