import React, { useEffect, useRef } from 'react';
import { DataSet, Network } from 'vis-network/standalone/esm/vis-network';
import useFetchData from '../hooks/useFetchData';
import useFetchUserNames from '../hooks/useFetchUserNames';

const levelColors = {
  "Level 1": "#8B0000", // Dark red
  "Level 2": "#FF4500", // Orange red
  "Level 3": "#FFD700", // Gold
  "Level 4": "#ADFF2F", // Green yellow
  "Level 5": "#008000"  // Green
};

const generateMindmapData = (data, userDetails) => {
  const nodes = new DataSet();
  const edges = new DataSet();
  const userNodes = new Set();

  data.forEach(entry => {
    const userNodeId = entry.user;
    const chosenUserNodeId = entry.user + "_to_" + entry.chosenUser + "_" + entry.level;

    const userName = userDetails[entry.user]?.name || entry.user;
    const userOrganization = userDetails[entry.user]?.organization ? ` (${userDetails[entry.user].organization})` : '';
    const chosenUserName = userDetails[entry.chosenUser]?.name || entry.chosenUser;
    const chosenUserOrganization = userDetails[entry.chosenUser]?.organization ? ` (${userDetails[entry.chosenUser].organization})` : '';

    const userLabel = `${userName}${userOrganization}`;
    const chosenUserLabel = `${chosenUserName}${chosenUserOrganization}`;

    if (!userNodes.has(userNodeId)) {
      nodes.add({ id: userNodeId, label: userLabel });
      userNodes.add(userNodeId);
    }

    if (!nodes.get(chosenUserNodeId)) {
      nodes.add({ id: chosenUserNodeId, label: chosenUserLabel });
    }

    edges.add({
      from: userNodeId,
      to: chosenUserNodeId,
      color: { color: levelColors[entry.level] },
      arrows: 'to'
    });
  });

  return { nodes, edges };
};

const Mindmap = () => {
  const data = useFetchData();  // Fetch data from Firebase
  const userDetails = useFetchUserNames();  // Fetch user names and organizations from Firebase
  const networkRef = useRef(null);

  useEffect(() => {
    if (data.length === 0 || Object.keys(userDetails).length === 0) return;  // Wait until data and user names are fetched

    const { nodes, edges } = generateMindmapData(data, userDetails);

    const container = networkRef.current;
    const options = {
      nodes: {
        shape: 'dot',
        size: 15,
        font: {
          size: 14,
        },
      },
      edges: {
        width: 2,
        color: {
          inherit: false,
        },
      },
      interaction: {
        navigationButtons: true,
        keyboard: true,
      },
      physics: {
        enabled: true,
        stabilization: {
          iterations: 150,
        },
      },
    };

    new Network(container, { nodes, edges }, options);
  }, [data, userDetails]);

  return (
    <div>
      <div ref={networkRef} style={{ height: '600px', border: '1px solid black', marginBottom: '20px' }} />
    </div>
  );
};

const App = () => (
  <div>
    <Mindmap />
  </div>
);

export default App;