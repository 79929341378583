import { useState, useEffect } from 'react';
import firebase from 'firebase/app';
import 'firebase/firestore';

const useFetchUserNames = () => {
    const db = firebase.firestore();

  const [userDetails, setUserDetails] = useState({});

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const snapshot = await db.collection('users').get();
        const userDetails = {};
        snapshot.forEach(doc => {
          const data = doc.data();
          userDetails[doc.id] = {
            name: data.name,
            organization: data.organization
          };
        });
        setUserDetails(userDetails);
      } catch (error) {
        console.error("Error fetching user details from Firebase: ", error);
      }
    };

    fetchUserDetails();
  }, []);

  return userDetails;
};

export default useFetchUserNames;