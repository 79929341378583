import React from 'react';
import Breadcrumb from './Breadcrumb';
import PageHeading from './PageHeading';

const Data = ({ data }) => {
 console.log(data);

 return (
  <div className="dashboard">
  <div className="dashboard-inner">
  <Breadcrumb
  to="/app"
  name="Dashboard"
/>
<PageHeading 
  title="Data"
  subtitle="Bekijk de resultaten!"
/>
  <div>
    <table>
      <thead>
        <tr>
          <th>Persoon A</th>
          <th>Persoon B</th>
          <th>Level</th>
          <th>Rating</th> {/* Vervang en voeg tabelheaders toe gebaseerd op je datastructuur */}
        </tr>
      </thead>
      <tbody>
        {data.map((item) => (
          <tr key={item.id}>
            <td>{item.user}</td>
            <td>{item.chosenUser}</td>
            <td>{item.deckId}</td>
            <td>{item.rating}</td> {/* Pas deze velden aan aan je eigen datastructuur */}
          </tr>
        ))}
      </tbody>
    </table>
  </div>
  </div>
  </div>
);
};

export default Data;
