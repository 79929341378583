/**
 * Displays the deck editor, and renders the card
 * editor accordion component.
 */

import React, { useState, useContext } from 'react';
import { firebaseAuth } from '../../provider/AuthProvider';
import { dbMethods } from '../../firebase/dbMethods';
import { useHistory } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeading } from '@fortawesome/free-solid-svg-icons';

import Accordion from '../Accordion';
import Breadcrumb from '../Breadcrumb';
import CardCreator from './CardCreator';
import PageHeading from '../PageHeading';
import TextInput from '../TextInput';

const DeckEditor = ({
  selectedDecks,
  deckToEdit,
  setDeckToEdit,
  cards
}) => {
  const { user } = useContext(firebaseAuth);
  const history = useHistory();
  const [title, setTitle] = useState(deckToEdit.title);
  const [isPublic, setIsPublic] = useState(!deckToEdit.private);
  const [updateSuccess, setUpdateSuccess] = useState(false);

  const updateDeck = (event) => {
    event.preventDefault();
    dbMethods.updateDeck(user, deckToEdit.id, title, !isPublic)
    setDeckToEdit({...deckToEdit, title, private: !isPublic});
    setUpdateSuccess(true);
    setTimeout(() => setUpdateSuccess(false), 3000);
  }

  const deleteDeck = (event) => {
    event.preventDefault();
    dbMethods.deleteDeck(user, deckToEdit.id);
    history.push('/manage');
    setDeckToEdit(null);
  }

  return (
    <>
      <Breadcrumb 
        to="/manage"
        name="Dashboard"
      />
      <PageHeading
        title="Verander fase."
        subtitle="Update de titel van de fase."
      />
      <form onSubmit={updateDeck}>
        <TextInput 
          labelText="Title"
          icon={<FontAwesomeIcon icon={faHeading} />}
          id="title"
          name="title"
          value={title}
          onChange={(event) => setTitle(event.target.value)}
          placeholder="Nieuwe Fase"
          autocomplete="off"
        />
        <input
          id="public"
          name="public"
          type="checkbox"
          checked={isPublic ? true : false}
          onChange={() => setIsPublic(!isPublic)}
        />
        <label htmlFor="public">
          <span></span>
          Is de fase publiekelijk?
        </label>
        <button
          className="btn btn-primary"
        >
          {updateSuccess ? "Success!" : "Update" }
        </button>
      </form>
      <div>
        <PageHeading 
          title="Kaarten."
          subtitle="Voeg toe, pas aan en verwijder kaarten."
          heading="h2"
        />
        <Accordion
          deckId={selectedDecks[0]}
          cards={cards}
        />
        <CardCreator 
          deckId={selectedDecks[0]}
        />
      </div>
      <div>
        <PageHeading 
          title="Verwijder fase."
          subtitle="Verwijder de gehele fase."
          heading="h2"
        />
        <form onSubmit={deleteDeck}>
          <button
            className="btn btn-warning"
          >Verwijder</button>
        </form>
      </div>
    </>
  );
}

export default DeckEditor;