import React, { useState, useEffect, useContext } from 'react';
import { dbMethods } from '../../firebase/dbMethods';
import { firebaseAuth } from '../../provider/AuthProvider';
const Analyse = ({ levels }) => {
  const [ratings, setRatings] = useState({});
  const [currentLevel, setCurrentLevel] = useState(Object.keys(levels)[0] || null);
  const { user } = useContext(firebaseAuth);

  // Effect voor het afhandelen van doorverwijzingen
  useEffect(() => {
    const rating = ratings[currentLevel];
    if (rating) {
      const allLevels = Object.keys(levels);
      const currentIndex = allLevels.indexOf(currentLevel);
      const levelData = levels[currentLevel];

      if (rating <= 2) {
        // Doorverwijzen bij een rating van 2 of minder
        window.location.href = levelData.link;
      } else if (currentIndex !== -1 && currentIndex < allLevels.length - 1 && rating >= 3) {
        // Verplaats naar het volgende level indien het huidige level voldoende hoog beoordeeld is en niet het laatste level is
        setCurrentLevel(allLevels[currentIndex + 1]);
      } else if (currentIndex === allLevels.length - 1 && rating >= 3) {
        // Doorverwijzen bij het laatste level als de rating hoog genoeg is
        window.location.href = levelData.exitLink;
      }
    }
  }, [ratings, currentLevel, levels]);

  // const handleRating = (level, rating) => {
  //   setRatings(prevRatings => ({
  //     ...prevRatings,
  //     [level]: rating
  //   }));
  // };
  const handleRating = (level, rating) => {
    setRatings(prevRatings => {
      const newRatings = { ...prevRatings, [level]: rating };
  
      // Update rating in Firebase
      // Zorg ervoor dat je de 'user' passend doorgeeft
  
      return newRatings;
    });
    dbMethods.updateDeckRating(level, rating, user); 
  };

  const renderStars = (level) => {
    let stars = [];
    const currentRating = ratings[level] || 0;
    for (let i = 1; i <= 5; i++) {
      stars.push(
        <span
          key={i}
          style={{ cursor: 'pointer', color: i <= currentRating ? 'gold' : '#0D4771', fontSize: '4.5em'}}
          onClick={() => handleRating(level, i)}
        >
          ★
        </span>
      );
    }
    return stars;
  };

  if (!levels || !currentLevel) {
    return <p>Loading levels or no levels found...</p>;
  }

  return (
    <div>
      {Object.entries(levels).map(([level, data]) => {
        if (level === currentLevel) {
          return (
            <div key={level}>
              <h3>{data.name}</h3>
              <ul>
                {data.titles.map((title, index) => (
                  <li key={index}>{title}</li>
                ))}
              </ul>
              <div>{renderStars(level)}</div>
            </div>
          );
        }
        return null;
      })}
    </div>
  );
};

export default Analyse;
