import { useState, useEffect } from 'react';
import firebase from 'firebase/app';
import 'firebase/firestore';

const useFetchData = () => {
    const db = firebase.firestore();

  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const snapshot = await db.collection('mindmap').get();
        const data = snapshot.docs.map(doc => doc.data());
        setData(data);
      } catch (error) {
        console.error("Error fetching data from Firebase: ", error);
      }
    };

    fetchData();
  }, []);

  return data;
};

export default useFetchData;