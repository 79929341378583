import React, { useState, useEffect, useContext } from 'react';
import { dbMethods } from '../../firebase/dbMethods';
import { firebaseAuth } from '../../provider/AuthProvider';

const AnalyseData = ({ levels }) => {
  const [ratings, setRatings] = useState({});
  const [currentLevel, setCurrentLevel] = useState(Object.keys(levels)[0] || null);
  const { user } = useContext(firebaseAuth);
  const [userData, setUserData] = useState(null);
  const [users, setUsers] = useState([]);
  const [chosenUser, setChosenUser] = useState('');

  useEffect(() => {
    if (user && user.uid) {
      dbMethods.getUserData(user.uid)
        .then(data => {
          setUserData(data);
        })
        .catch(err => {
          console.error("Failed to fetch user data:", err);
        });
    }
  }, [user]);

  useEffect(() => {
    dbMethods.getAllUserNames()
      .then(users => {
        setUsers(users);
      })
      .catch(err => {
        console.error("Failed to fetch users:", err);
      });
  }, []);

  useEffect(() => {
    const rating = ratings[currentLevel];
    if (rating) {
      const allLevels = Object.keys(levels);
      const currentIndex = allLevels.indexOf(currentLevel);
      const levelData = levels[currentLevel];
      if (rating <= 2) {
        dbMethods.updateMindmap(currentLevel, user.uid, chosenUser);
        window.location.href = levelData.link;
      } else if (currentIndex !== -1 && currentIndex < allLevels.length - 1 && rating >= 3) {
        setCurrentLevel(allLevels[currentIndex + 1]);
      } else if (currentIndex === allLevels.length - 1 && rating >= 3) {
        dbMethods.updateMindmap(currentLevel, user.uid, chosenUser);
        window.location.href = levelData.exitLink;
      }
    }
  }, [ratings, currentLevel, levels, user, chosenUser]);

  const handleRating = (level, rating) => {
    setRatings(prevRatings => {
      const newRatings = { ...prevRatings, [level]: rating };
      dbMethods.updateDeckRating(level, rating, user.uid, chosenUser);
      return newRatings;
    });
  };

  const renderStars = (level) => {
    let stars = [];
    const currentRating = ratings[level] || 0;
    for (let i = 1; i <= 5; i++) {
      stars.push(
        <span
          key={i}
          style={{ cursor: 'pointer', color: i <= currentRating ? 'gold' : '#0D4771', fontSize: '4.5em' }}
          onClick={() => handleRating(level, i)}
        >
          ★
        </span>
      );
    }
    return stars;
  };

  const renderUserSelection = () => (
    <div className="user-selection">
      <label htmlFor="userDropdown" className="user-label">Met wie ga je de analyse doen? </label>
      <select
        id="userDropdown"
        className="user-dropdown"
        value={chosenUser}
        onChange={(e) => setChosenUser(e.target.value)}
      >
        <option value="" disabled>Selecteer</option>
        {users.map(user => (
          <option key={user.id} value={user.id}>{user.name}</option>
        ))}
      </select>
    </div>
  );

  if (!levels || !currentLevel) {
    return <p>Loading levels or no levels found...</p>;
  }

  return (
    <div className="analyse-container">
      {!chosenUser ? renderUserSelection() : (
        <div>
          <div className="user-label">Geselecteerd: {users.find(u => u.id === chosenUser)?.name}</div>
          {Object.entries(levels).map(([level, data]) => {
            if (level === currentLevel) {
              return (
                <div key={level}>
                  <h3>{data.name}</h3>
                  <ul>
                    {data.titles.map((title, index) => (
                      <li key={index}>{title}</li>
                    ))}
                  </ul>
                  <div>{renderStars(level)}</div>
                </div>
              );
            }
            return null;
          })}
        </div>
      )}
    </div>
  );
};

export default AnalyseData;